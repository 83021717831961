import React from 'react';
import cls from 'classnames';

import { Tooltip, Text, ButtonPriority } from 'wix-ui-tpa';
import { getScheduleTbdMessage, getStartDate } from '@wix/social-groups-api';

import {
  Event as IEvent,
  Image as IImage,
} from '@wix/ambassador-events-v1-event/types';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import ChevronUp from 'wix-ui-icons-common/ChevronUp';

import { getScaleToFillImageURL } from 'image-client-api/dist/imageClientSDK';

import { classes, st } from './Event.st.css';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Button } from '../Button';
import { groupGroupEventsButtonClicks } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from '../../bi-logger/types';
import { getEventPage } from './getEventPage';

interface IEventProps {
  event: IEvent;
  groupId: string;
  last?: boolean;
}

type Props = IEventProps;

const EventComponent: React.FC<Props> = ({ event, last, groupId }) => {
  const [opened, setOpened] = React.useState(false);
  const { t } = useTranslation();
  const bi = useBi();
  const { isMobile } = useEnvironment();

  const scheduleTbdMessage = getScheduleTbdMessage(event);

  const renderDate = () => {
    if (scheduleTbdMessage) {
      return (
        <p className={classes.date}>
          <Text className={classes.day}>{t('groups-web.events.date_tbd')}</Text>
        </p>
      );
    }

    const [day, weekday, month] = getStartDate(event, 'DD/ddd/MMM').split('/');

    return (
      <p className={classes.date}>
        <Text className={classes.day}>{day}</Text>
        <div>
          <Text className={classes.weekday} tagName="p">
            {weekday}
          </Text>
          <Text className={classes.month} tagName="p">
            {month}
          </Text>
        </div>
      </p>
    );
  };

  const backgroundImage = getImageSrc(event.mainImage);
  const { eventPageUrl } = event;
  const eventPage = getEventPage(eventPageUrl);

  return (
    <article
      className={st(
        classes.root,
        { mobile: isMobile } as any,
        cls({
          [classes.last]: last,
          [classes.opened]: opened,
        }),
      )}
      onClick={toggle}
    >
      <div className={classes.content}>
        <div className={classes.meta_wrapper}>
          <div className={classes.meta}>
            <div
              className={classes.image}
              style={{
                backgroundImage: backgroundImage
                  ? `url(${backgroundImage})`
                  : undefined,
              }}
            />
            {renderDate()}
          </div>
        </div>
        <div className={classes.description}>
          <Text className={classes.title}>{event.title}</Text>
          <Text className={cls(classes.separator, classes.hover_hide)}>
            &nbsp;/&nbsp;
          </Text>
          <Text className={cls(classes.separator, classes.hover_show)}>
            {opened ? (
              <ChevronUp className={classes.chevron} />
            ) : (
              <ChevronDown className={classes.chevron} />
            )}
          </Text>
          <Text className={classes.location}>{event.location!.name}</Text>
        </div>
      </div>
      <Tooltip
        disabled={!!eventPage}
        content={t('groups-web.events.setup-warning')}
        className={classes.cta}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Button
          as="a"
          disabled={!eventPage}
          href={eventPage}
          priority={ButtonPriority.primary}
          onClick={preventBubbling}
          fullWidth={isMobile}
          {...({ target: '_blank' } as any)}
        >
          {t('groups-web.events.rsvp')}
        </Button>
      </Tooltip>
      <div className={classes.break} />
      <div className={classes.details} aria-hidden={!opened}>
        <Text className={classes.text} tagName="p">
          {scheduleTbdMessage
            ? scheduleTbdMessage
            : getStartDate(event, 'DD MMM., HH:mm')}
        </Text>
        <Text className={classes.text} tagName="p">
          {event.location!.address || event.location!.name}
        </Text>
        <Text className={cls(classes.about, classes.text)} tagName="p">
          {event.description}
        </Text>
      </div>
    </article>
  );

  function preventBubbling(e: any) {
    e.stopPropagation();
    bi.report(
      groupGroupEventsButtonClicks({
        origin: 'live_site_events_list',
        groupId,
        userEntry: BIUserEntry.SITE,
        buttonName: 'RSVP',
      }),
    );
  }

  function toggle() {
    setOpened(!opened);
    bi.report(
      groupGroupEventsButtonClicks({
        origin: 'live_site_events_list',
        groupId,
        userEntry: BIUserEntry.SITE,
        buttonName: 'expand',
      }),
    );
  }
};

function getImageSrc(image?: IImage) {
  if (!image) {
    return;
  }

  return getScaleToFillImageURL(image.id, image.width, image.height, 80, 80, {
    quality: 90,
  });
}

export const Event = EventComponent as React.ComponentType<IEventProps>;
