import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import { IconButton } from 'wix-ui-tpa';
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall';

import { ModalParentNodeRefConsumer } from './ModalParentNodeRefContext';
import { classes } from './ReactModal.st.css';

export interface ModalProps extends ReactModal.Props {
  isOpen: boolean;
  isMobile?: boolean;

  onRequestClose(e?: any): void;
  parentSelector?: any;
}

export class Modal extends React.Component<ModalProps> {
  render() {
    const { onRequestClose, isMobile, ...modalProps } = this.props;

    return (
      <ModalParentNodeRefConsumer>
        {(appNode) => {
          if (appNode) {
            modalProps.parentSelector = () => appNode;
          }
          return (
            <ReactModal
              overlayClassName={classes.overlay}
              ariaHideApp={false}
              onRequestClose={onRequestClose}
              className={cn(classes.root, isMobile ? classes.mobile : null)}
              {...modalProps}
            >
              <div className={classes.closeButtonRow}>
                <div className={classes.closeButtonWrapper}>
                  <IconButton
                    onClick={onRequestClose}
                    icon={<CloseSmall />}
                    className={classes.closeIcon}
                  />
                </div>
              </div>
              {this.props.children}
            </ReactModal>
          );
        }}
      </ModalParentNodeRefConsumer>
    );
  }
}
