import React from 'react';
import classnames from 'classnames';

import {
  ImageLoadingBehaviorOptions,
  ThumbnailImage,
  ImageResizeOptions,
  ThumbnailImageProps,
} from 'wix-ui-tpa';

import { GroupCardProps } from '../types';
import { ImageRatio } from '../../../settings/groups-list/settingsConstants';
import { classes } from './GroupCardMedia.st.css';

export interface GroupCardMediaProps {
  url: string;
  ratio: ImageRatio;
  groupUrl: string;
  goToGroup?: GroupCardProps['goToGroup'];
  resize?: ImageResizeOptions;
}
const noop = () => {};

export const GroupCardMedia: React.FC<
  GroupCardMediaProps & Partial<ThumbnailImageProps>
> = ({ ratio, url, goToGroup, groupUrl, ...tmb }) => {
  const aspectRatio = ratio === ImageRatio.square ? 'square' : 'cinema';
  return (
    <a
      tabIndex={-1}
      href={groupUrl}
      onClick={goToGroup}
      data-hook="group-card-media"
      data-bi-origin="group_image"
      className={classnames(classes.root, {
        [classes.empty]: !url,
      })}
    >
      {url && (
        <ThumbnailImage
          fluid
          src={url}
          aspectRatio={aspectRatio}
          loadingBehavior={ImageLoadingBehaviorOptions.blur}
          {...tmb}
        />
      )}
    </a>
  );
};

GroupCardMedia.displayName = 'GroupCardMedia';
GroupCardMedia.defaultProps = {
  goToGroup: noop,
};
