import React from 'react';
import { Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';

import { Change } from '../Icons/Change';
import { Delete } from '../Icons/Delete';
import { PhotoCamera } from '../Icons/PhotoCamera';
import { Button } from '../Button';

import { st, classes } from './ImageLoader.st.css';

interface ImageLoaderProps {
  uploadImage(image: File): void;
  imageUrl?: string;
  changeImageLabel?: string;
  deleteImageLabel?: string;
  uploadImageLabel?: string;
  loadingLabel?: string;
  className?: string;
  forceBlackAndWhite?: boolean;
}
export interface ImageLoaderState {
  loading: boolean;
  focused: boolean;
  imageUrl?: string;
}
export class ImageLoader extends React.Component<
  ImageLoaderProps,
  ImageLoaderState
> {
  private readonly $input = React.createRef<HTMLInputElement>();

  constructor(props: Readonly<ImageLoaderProps>) {
    super(props);
    this.state = {
      loading: false,
      imageUrl: props.imageUrl,
      focused: false,
    };
  }

  handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const [userImage] = Array.from(event.target.files!);
    try {
      this.props.uploadImage && this.props.uploadImage(userImage);
      this.setState({ imageUrl: URL.createObjectURL(userImage) });
    } catch (e) {
      // TODO:show error(?)
      console.log(e);
    } finally {
      this.$input.current!.value = null as any;
    }
  };

  handleImageChange = () => {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }
    this.removeFocused();
    return this.handleUploadButtonClick();
  };

  private readonly removeFocused = () => {
    this.setState({ focused: false });
  };

  private readonly addFocused = () => {
    this.setState({ focused: true });
  };

  handleImageDelete = () => {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }
    this.props.uploadImage(null as any);
    this.setState({ imageUrl: null as any, focused: false });
  };

  handleUploadButtonClick = () => this.$input.current!.click();
  render() {
    const {
      changeImageLabel,
      deleteImageLabel,
      uploadImageLabel,
      loadingLabel,
      className,
      forceBlackAndWhite,
    } = this.props;
    const { loading, imageUrl, focused } = this.state;

    if (loading) {
      return (
        <div
          className={st(classes.root, { bw: !!forceBlackAndWhite }, className)}
        >
          <Text data-hook="loading-container" className={classes.loading}>
            {loadingLabel || 'Loading...'}
          </Text>
        </div>
      );
    }

    return (
      <div
        className={st(classes.root, { bw: !!forceBlackAndWhite }, className)}
      >
        {imageUrl ? (
          <div
            data-hook="editor"
            className={st(classes.editor, {}, focused ? classes.focused : '')}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
            tabIndex={0}
            onFocus={this.addFocused}
          >
            <div className={classes.actions}>
              <button
                data-hook="change-image-button"
                className={classes.button}
                onClick={this.handleImageChange}
                tabIndex={0}
                onKeyDown={this.onGoingUp}
              >
                <Change />
                <Text
                  typography={TextTypography.runningText}
                  className={classes.label}
                >
                  {changeImageLabel || 'Change Image'}
                </Text>
              </button>
              <button
                data-hook="delete-image-button"
                className={classes.button}
                onClick={this.handleImageDelete}
                onKeyDown={this.onGoingDown}
                tabIndex={0}
              >
                <Delete />
                <Text
                  typography={TextTypography.runningText}
                  className={classes.label}
                >
                  {deleteImageLabel || 'Delete Image'}
                </Text>
              </button>
            </div>
          </div>
        ) : (
          <div className={classes.uploadButtonWrapper}>
            <Button
              data-hook="upload-image-button"
              priority={ButtonPriority.secondary}
              fullWidth
              onClick={this.handleUploadButtonClick}
              className={classes.uploadButton}
            >
              <PhotoCamera />
              <span>{uploadImageLabel || 'Upload Image'}</span>
            </Button>
          </div>
        )}
        <input
          hidden
          data-hook="image-input"
          type="file"
          multiple={false}
          ref={this.$input}
          onChange={this.handleFileInputChange}
        />
      </div>
    );
  }

  private readonly onGoingDown = (e: React.KeyboardEvent) => {
    // going down
    if (e.key === 'Tab' && !e.shiftKey) {
      this.removeFocused();
    }
  };

  private readonly onGoingUp = (e: React.KeyboardEvent) => {
    // going up
    if (e.key === 'Tab' && e.shiftKey) {
      this.removeFocused();
    }
  };
}
