import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Event as IEvent } from '@wix/ambassador-events-v1-event/types';

import { Layout, LayoutProps } from '../Layout';
import { useSiteNavigation } from '../../../../components/Group/Widget/hooks/useSiteNavigation';
import { Button } from '../../Button';
import { classes } from '../Layout.st.css';

interface Props {
  past: IEvent[];
  upcoming: IEvent[];
  onViewEvents(events: IEvent[]): void;
}

export const Restricted: React.FC<Props> = ({
  past,
  upcoming,
  onViewEvents,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const siteNavigation = useSiteNavigation();
  let p: LayoutProps;

  if (upcoming.length) {
    p = {
      cta: (
        <Button
          priority={ButtonPriority.primary}
          onClick={() => onViewEvents(upcoming)}
          className={classes.cta}
        >
          {t('groups-web.restriction.view-event', {
            count: upcoming.length,
          })}
        </Button>
      ),
      mobile: isMobile,
      subtitle: t('groups-web.restriction.register.event'),
      title: t('groups-web.restriction.register'),
    };
  } else {
    const [_, groupsUrl] = siteNavigation;
    p = {
      title: t('groups-web.restriction.invalid.title'),
      subtitle: t('groups-web.restriction.invalid.subtitle', {
        count: past.length,
      }),
      mobile: isMobile,
      cta: (
        <Button
          as="a"
          href={groupsUrl.href}
          priority={ButtonPriority.primary}
          className={classes.cta}
        >
          {t('groups-web.restriction.invalid.action')}
        </Button>
      ),
    };
  }
  return <Layout {...p!} />;
};

Restricted.displayName = 'Restricted';
